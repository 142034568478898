import React, { useState } from 'react'

import { Box, FormGroup, RichTextarea } from '@te-digi/styleguide'

const RichTextareaMarkdownExample1 = () => {
  const [value, setValue] = useState('- **Lihavoitu**\n- _Kursivoitu_')

  return typeof window === 'undefined' ? null : (
    <>
      <FormGroup>
        <RichTextarea
          counter
          help="Help"
          label="Label"
          maxLength={100}
          onChange={setValue}
          placeholder="Kirjoita…"
          required
          subLabel="Sub Label"
          value={value}
        />
      </FormGroup>
      {value && (
        <Box noMargin>
          <code style={{ whiteSpace: 'pre-wrap' }}>{value}</code>
        </Box>
      )}
    </>
  )
}

export { RichTextareaMarkdownExample1 }
