import React from 'react'

import {
  DescriptionList,
  DescriptionListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from './Code'

export const TextFieldCharacters = () => (
  <>
    <Paragraph>
      Komponentti näyttää virheilmoituksen, kun siihen syötetään ei-sallittuja
      merkkejä. Seuraavat merkit ovat sallittuja:
    </Paragraph>
    <DescriptionList variant="striped">
      {[
        {
          unicode: <Code>U+000A</Code>,
          description: 'LF'
        },
        {
          unicode: <Code>U+000D</Code>,
          description: 'CR'
        },
        {
          unicode: (
            <>
              <Code>U+0020</Code>–<Code>U+007E</Code>
            </>
          ),
          description: 'Välilyönti–Tilde'
        },
        {
          unicode: (
            <>
              <Code>U+00A1</Code>–<Code>U+00AC</Code>
            </>
          ),
          description: '¡¢£¤¥¦§¨©ª«¬'
        },
        {
          unicode: (
            <>
              <Code>U+00AE</Code>–<Code>U+00BF</Code>
            </>
          ),
          description: '®¯°±²³´µ¶·¸¹º»¼½¾¿'
        },
        {
          unicode: (
            <>
              <Code>U+00C0</Code>–<Code>U+00D6</Code>
            </>
          ),
          description: 'À–Ö'
        },
        {
          unicode: <Code>U+00D7</Code>,
          description: '×'
        },
        {
          unicode: (
            <>
              <Code>U+00D8</Code>–<Code>U+00F6</Code>
            </>
          ),
          description: 'Ø–ö'
        },
        {
          unicode: <Code>U+00F7</Code>,
          description: '÷'
        },
        {
          unicode: (
            <>
              <Code>U+00F8</Code>–<Code>U+00FF</Code>
            </>
          ),
          description: 'ø–ÿ'
        },
        {
          unicode: (
            <>
              <Code>U+0100</Code>–<Code>U+017F</Code>
            </>
          ),
          description: 'Latin Extended-A'
        },
        {
          unicode: (
            <>
              <Code>U+0180</Code>–<Code>U+024F</Code>
            </>
          ),
          description: 'Latin Extended-B'
        },
        {
          unicode: (
            <>
              <Code>U+0250</Code>–<Code>U+02AF</Code>
            </>
          ),
          description: 'IPA Extensions'
        },
        {
          unicode: <Code>U+20AC</Code>,
          description: '€'
        }
      ].map(({ unicode, description }) => (
        <DescriptionListItem
          key={description}
          label={unicode}
          labelWidth={3}
        >
          {description}
        </DescriptionListItem>
      ))}
    </DescriptionList>
  </>
)
