import React, { useState } from 'react'

import { Box, FormGroup, RichTextarea } from '@te-digi/styleguide'

const RichTextareaHtmlExample1 = () => {
  const [value, setValue] = useState(
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p><p>Vestibulum commodo, lorem ac finibus gravida, purus purus pulvinar nulla, eget venenatis dolor lectus sit amet lectus.</p>'
  )

  return typeof window === 'undefined' ? null : (
    <>
      <FormGroup>
        <RichTextarea
          format="html"
          counter
          label="Label"
          maxLength={500}
          onChange={setValue}
          placeholder="Kirjoita…"
          value={value}
        />
      </FormGroup>
      {value && (
        <Box noMargin>
          <code style={{ whiteSpace: 'pre-wrap' }}>{value}</code>
        </Box>
      )}
    </>
  )
}

export { RichTextareaHtmlExample1 }
