import React, { ComponentProps, useState } from 'react'

import {
  Box,
  FormGroup,
  InputGroupText,
  Label,
  RichTextarea
} from '@te-digi/styleguide'

const TOOLBAR: ComponentProps<typeof RichTextarea>['toolbar'] = [
  'bold',
  'italic'
]

const RichTextareaMarkdownExample2 = () => {
  const [value, setValue] = useState('**Lihavoitu**\n- _Kursivoitu_')

  return typeof window === 'undefined' ? null : (
    <>
      <FormGroup ariaLabelledby="rich-textarea-label">
        <Label
          as="p"
          id="rich-textarea-label"
        >
          Label
        </Label>
        <RichTextarea
          addonPrepend={<InputGroupText>FI</InputGroupText>}
          ariaLabel="Label suomeksi"
          counter
          error="Error"
          maxLength={5000}
          onChange={setValue}
          placeholder="Kirjoita…"
          toolbar={TOOLBAR}
          value={value}
          lang="fi"
        />
      </FormGroup>
      {value && (
        <Box noMargin>
          <code style={{ whiteSpace: 'pre-wrap' }}>{value}</code>
        </Box>
      )}
    </>
  )
}

export { RichTextareaMarkdownExample2 }
