import React from 'react'

import {
  FormGroup,
  List,
  ListItem,
  Paragraph,
  RichTextarea
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { TextFieldCharacters } from '../../components/TextFieldCharacters'
import { RichTextareaMarkdownExample1 } from '../../examples/react/RichTextareaMarkdownExample1'
import { RichTextareaMarkdownExample2 } from '../../examples/react/RichTextareaMarkdownExample2'
import { RichTextareaHtmlExample1 } from '../../examples/react/RichTextareaHtmlExample1'
import { RichTextareaHtmlExample2 } from '../../examples/react/RichTextareaHtmlExample2'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="RichTextarea"
    components={[{ component: RichTextarea }]}
  >
    <Section title="Saavutettavuus">
      <Paragraph>
        Wave ilmoittaa virheellisesti saavutettavuusongelmasta, että{' '}
        <Code>RichTextarea</Code>n labelia ja inputia ei ole yhdistetty oikein
        (Orphaned form label). Tämä ei vaadi toimenpiteitä. Ilmoitus johtuu{' '}
        <Code>RichTextarea</Code>n teknisen toteutuksen monimutkaisuudesta.
        Kyseessä ei ole natiivi html textarea (joka ei mahdollista tekstin
        muotoilua), vaan tekstin muotoiluja esittävä komponentti. Toteutus on
        saavutettava ja ruudunlukijalla kuunneltaessa tieto välittyy oikein.
      </Paragraph>
      <Paragraph>
        On kuitenkin huomioitava, että kielistetyille kentille tulee antaa
        erillinen <Code>lang</Code>-attribuutti, jotta ruudunlukija osaa lukea
        kentän oikealla kielellä.
      </Paragraph>
    </Section>

    <Section title="Muotoilut">
      <List variant="unordered">
        <ListItem>
          <Code>RichTextarea</Code> tukee oletuksena seuraavia Markdown- ja
          HTML- muotoiluja:
          <List variant="unordered">
            <ListItem>lihavointi</ListItem>
            <ListItem>kursivointi</ListItem>
            <ListItem>numerointi</ListItem>
            <ListItem>luettelomerkit</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Työkalupalkkiin voi valita tilanteen tarvitsemat toiminnot.
        </ListItem>
      </List>
    </Section>
    <Section title="Markdown">
      <Playground
        example={RichTextareaMarkdownExample1}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        example={RichTextareaMarkdownExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="HTML">
      <Playground
        example={RichTextareaHtmlExample1}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        example={RichTextareaHtmlExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Disabloitu">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <RichTextarea
            disabled
            help="Help"
            label="Label"
            onChange={() => {
              // This is intentional
            }}
            subLabel="Sub Label"
            value=""
          />
        </FormGroup>
      </Playground>
    </Section>
    <Section title="Virheviesti ja merkkilaskuri">
      <List variant="unordered">
        <ListItem>
          <Code>RichTextarea</Code>-komponentin alapuolella oleva tila on
          varattu virheviestille ja merkkilaskurille.
        </ListItem>
        <ListItem>
          Virheviestiä käyttäessä komponentin reunaviiva värjäytyy{' '}
          <Code>danger</Code> värillä.
        </ListItem>
        <ListItem>
          Syötettävää merkkimäärää ei voi rajoittaa. Mahdollinen katkaisu kesken
          koodia voisi aiheuttaa sovelluksen kaatumisen. Täten merkkimäärä voi
          mennä yli, jolloin se ilmaistaan <Code>danger</Code> värillä.
        </ListItem>
        <ListItem>
          Merkkilaskuri ilmestyy näkyviin, kun tekstiä on syötetty 80 %
          merkkimäärästä. Kynnystä on mahdollista muuttaa.
        </ListItem>
      </List>
    </Section>
    <Section title="Sallitut merkit">
      <TextFieldCharacters />
    </Section>
  </Content>
)

export default Page
